// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-access-js": () => import("./../../../src/pages/access.js" /* webpackChunkName: "component---src-pages-access-js" */),
  "component---src-pages-auditions-js": () => import("./../../../src/pages/auditions.js" /* webpackChunkName: "component---src-pages-auditions-js" */),
  "component---src-pages-bringing-frozen-to-life-js": () => import("./../../../src/pages/bringing-frozen-to-life.js" /* webpackChunkName: "component---src-pages-bringing-frozen-to-life-js" */),
  "component---src-pages-cast-and-creatives-js": () => import("./../../../src/pages/cast-and-creatives.js" /* webpackChunkName: "component---src-pages-cast-and-creatives-js" */),
  "component---src-pages-competitionterms-js": () => import("./../../../src/pages/competitionterms.js" /* webpackChunkName: "component---src-pages-competitionterms-js" */),
  "component---src-pages-faqs-js": () => import("./../../../src/pages/faqs.js" /* webpackChunkName: "component---src-pages-faqs-js" */),
  "component---src-pages-four-or-more-js": () => import("./../../../src/pages/four-or-more.js" /* webpackChunkName: "component---src-pages-four-or-more-js" */),
  "component---src-pages-gallery-js": () => import("./../../../src/pages/gallery.js" /* webpackChunkName: "component---src-pages-gallery-js" */),
  "component---src-pages-groups-js": () => import("./../../../src/pages/groups.js" /* webpackChunkName: "component---src-pages-groups-js" */),
  "component---src-pages-hygge-t-4-js": () => import("./../../../src/pages/hygge-t4.js" /* webpackChunkName: "component---src-pages-hygge-t-4-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-interstitial-js": () => import("./../../../src/pages/interstitial.js" /* webpackChunkName: "component---src-pages-interstitial-js" */),
  "component---src-pages-let-it-go-js": () => import("./../../../src/pages/let-it-go.js" /* webpackChunkName: "component---src-pages-let-it-go-js" */),
  "component---src-pages-samsung-js": () => import("./../../../src/pages/samsung.js" /* webpackChunkName: "component---src-pages-samsung-js" */),
  "component---src-pages-termsandconditions-js": () => import("./../../../src/pages/termsandconditions.js" /* webpackChunkName: "component---src-pages-termsandconditions-js" */),
  "component---src-pages-ticket-information-js": () => import("./../../../src/pages/ticket-information.js" /* webpackChunkName: "component---src-pages-ticket-information-js" */)
}

